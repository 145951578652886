<script>
import api from '@/api'
import tool, { treearr } from '@/tool'
import base from '@/templates/list'
export default {
  directives: {},
  filters: {},
  components: {
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: tool.checkButton('bom-add'), title: 'global.add' },
        remove: { show: tool.checkButton('bom-remove'), title: 'global.remove' },
        edit: { show: tool.checkButton('bom-edit'), title: 'global.edit' },
        view: { show: tool.checkButton('bom-info'), title: 'global.details' },
        valid: { show: false }
      },
      showList: [
        {
          copName: 'el-input',
          placeholder: '产品名称',
          key: 'query',
          name: '',
          attrs: ['placeholder']
        }
      ],
      formInline: {
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      tableTile: [{
        key: '1',
        columnLabel: '产品名称',
        prop: 'productName',
        width: ''
      },
      {
        key: '2',
        columnLabel: '产品编码',
        prop: 'code',
        width: ''
      },
      {
        key: '3',
        columnLabel: '单位',
        prop: 'unit',
        width: ''
      },
      {
        key: '4',
        columnLabel: '创建人',
        prop: 'createBy',
        width: ''
      },
      {
        key: '5',
        columnLabel: '创建日期',
        prop: 'createTime',
        width: ''
      },
      {
        key: '6',
        columnLabel: '备注',
        prop: 'remark',
        width: ''
      },
      ]
    }
  },
  created () {
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true
      api.auth.getAuthUserList({
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.formInline.page.total = res.data.total
        this.loading = false
      })
    },

    // 新增
    add () {
      this.$router.push('/bom-add')
    },

    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      this.toPage(1)
    },

    // 编辑
    async edit (id) {
    },

    // 详情
    async view (id) {
    },
  }
}
</script>
